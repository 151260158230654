import React from 'react'

import Layout from 'components/Layout'

const NotFound = () => (
  <Layout>
    <oma-grid-row contentAlignedWithGrid fullWidth class='section'>
      <h2>Page not found</h2>
      <p>Wops! This page does not exist!</p>
    </oma-grid-row>
  </Layout>
)

export default NotFound
